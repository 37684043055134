/* eslint-disable no-undef */
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

export const getServerEnvironment = () => {
  if (process.env.VERCEL_URL && process.env.VERCEL_URL.includes('deepr-staging.vercel.app')) {
    return 'staging'
  }

  return process.env.VERCEL_ENV
}

export const getClientEnvironment = () => {
  const vercelUrl = process.env.NEXT_PUBLIC_VERCEL_URL
  if (vercelUrl && vercelUrl.includes('deepr-staging.vercel.app')) {
    return 'staging'
  }

  return process.env.NEXT_PUBLIC_VERCEL_ENV
}

export const getSentryConfig = () => {
  return {
    dsn: SENTRY_DSN || 'https://24e48de53fc74066993b6c2b24bd40d3@o4504735362973696.ingest.sentry.io/4504735744262144',
    tracesSampleRate: 1.0,
  }
}

export const getIgnoreErrors = () => [
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications.',
]
