/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  JumpRateModelV2,
  JumpRateModelV2Interface,
} from "../JumpRateModelV2";

const _abi = [
  {
    type: "constructor",
    payable: false,
    inputs: [
      {
        type: "uint256",
        name: "baseRatePerYear",
      },
      {
        type: "uint256",
        name: "multiplierPerYear",
      },
      {
        type: "uint256",
        name: "jumpMultiplierPerYear",
      },
      {
        type: "uint256",
        name: "kink_",
      },
      {
        type: "address",
        name: "owner_",
      },
    ],
  },
  {
    type: "event",
    anonymous: false,
    name: "NewInterestParams",
    inputs: [
      {
        type: "uint256",
        name: "baseRatePerSecond",
        indexed: false,
      },
      {
        type: "uint256",
        name: "multiplierPerSecond",
        indexed: false,
      },
      {
        type: "uint256",
        name: "jumpMultiplierPerSecond",
        indexed: false,
      },
      {
        type: "uint256",
        name: "kink",
        indexed: false,
      },
    ],
  },
  {
    type: "function",
    name: "baseRatePerSecond",
    constant: true,
    stateMutability: "view",
    payable: false,
    inputs: [],
    outputs: [
      {
        type: "uint256",
      },
    ],
  },
  {
    type: "function",
    name: "getBorrowRatePerSecond",
    constant: true,
    stateMutability: "view",
    payable: false,
    inputs: [
      {
        type: "uint256",
        name: "cash",
      },
      {
        type: "uint256",
        name: "borrows",
      },
      {
        type: "uint256",
        name: "reserves",
      },
    ],
    outputs: [
      {
        type: "uint256",
      },
    ],
  },
  {
    type: "function",
    name: "getSupplyRatePerSecond",
    constant: true,
    stateMutability: "view",
    payable: false,
    inputs: [
      {
        type: "uint256",
        name: "cash",
      },
      {
        type: "uint256",
        name: "borrows",
      },
      {
        type: "uint256",
        name: "reserves",
      },
      {
        type: "uint256",
        name: "reserveFactorMantissa",
      },
    ],
    outputs: [
      {
        type: "uint256",
      },
    ],
  },
  {
    type: "function",
    name: "isInterestRateModel",
    constant: true,
    stateMutability: "view",
    payable: false,
    inputs: [],
    outputs: [
      {
        type: "bool",
      },
    ],
  },
  {
    type: "function",
    name: "jumpMultiplierPerSecond",
    constant: true,
    stateMutability: "view",
    payable: false,
    inputs: [],
    outputs: [
      {
        type: "uint256",
      },
    ],
  },
  {
    type: "function",
    name: "kink",
    constant: true,
    stateMutability: "view",
    payable: false,
    inputs: [],
    outputs: [
      {
        type: "uint256",
      },
    ],
  },
  {
    type: "function",
    name: "multiplierPerSecond",
    constant: true,
    stateMutability: "view",
    payable: false,
    inputs: [],
    outputs: [
      {
        type: "uint256",
      },
    ],
  },
  {
    type: "function",
    name: "owner",
    constant: true,
    stateMutability: "view",
    payable: false,
    inputs: [],
    outputs: [
      {
        type: "address",
      },
    ],
  },
  {
    type: "function",
    name: "updateJumpRateModel",
    constant: false,
    payable: false,
    inputs: [
      {
        type: "uint256",
        name: "baseRatePerYear",
      },
      {
        type: "uint256",
        name: "multiplierPerYear",
      },
      {
        type: "uint256",
        name: "jumpMultiplierPerYear",
      },
      {
        type: "uint256",
        name: "kink_",
      },
    ],
    outputs: [],
  },
  {
    type: "function",
    name: "utilizationRate",
    constant: true,
    stateMutability: "pure",
    payable: false,
    inputs: [
      {
        type: "uint256",
        name: "cash",
      },
      {
        type: "uint256",
        name: "borrows",
      },
      {
        type: "uint256",
        name: "reserves",
      },
    ],
    outputs: [
      {
        type: "uint256",
      },
    ],
  },
];

export class JumpRateModelV2__factory {
  static readonly abi = _abi;
  static createInterface(): JumpRateModelV2Interface {
    return new utils.Interface(_abi) as JumpRateModelV2Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): JumpRateModelV2 {
    return new Contract(address, _abi, signerOrProvider) as JumpRateModelV2;
  }
}
