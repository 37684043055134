import { useEffect, useState } from 'react'
import { Box, Image, useDisclosure } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useNetwork } from 'wagmi'

import Footer from '~/components/layout/Footer'
import Header from '~/components/layout/Header'
import NetworkWarningBanner from '~/components/layout/NetworkBanner'
import { LAYOUT_CALC } from '~/constants'
import { SUPPORTED_NETWORKS } from '~/constants/tokens'
import { useHydratedAccount } from '~/hooks/useHydratedAccount'
import { hasShownDegenDisclaimerModal, setDegenDisclaimerModalAsShown } from '~/lib/localStorage'
import DegenDisclaimerModal from '../dashboard/DegenDisclaimerModal'
import DegenDisclaimerBanner from './DegenDisclaimerBanner'

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { chain } = useNetwork()
  const { pathname } = useRouter()
  const { isConnected } = useHydratedAccount()

  const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure()

  const {
    isOpen: isDegenDisclaimerModalOpen,
    onOpen: onDegenDisclaimerModalOpen,
    onClose: onDegenDisclaimerModalClose,
  } = useDisclosure()

  const isHome = pathname === '/'
  const isDashboard = pathname === '/dashboard'
  const isSubieMint = pathname === '/subies'

  const showNetworkWarningBanner =
    (isDashboard || isSubieMint) && isConnected && chain?.id && !SUPPORTED_NETWORKS.includes(chain?.id) && !isOpenDrawer

  const showDegenDisclaimerBanner = isHome || isDashboard

  const [hasDegenDisclaimerModalBeenShown, setHasDegenDisclaimerModalBeenShown] = useState<boolean | undefined>()

  useEffect(() => {
    const checkIfShouldShowDegenDisclaimerModal = async () => {
      const hasBeenShown = await hasShownDegenDisclaimerModal()

      setHasDegenDisclaimerModalBeenShown(!!hasBeenShown)

      if (isDashboard && !hasBeenShown) {
        onDegenDisclaimerModalOpen()
      }
    }

    checkIfShouldShowDegenDisclaimerModal()
  }, [isDashboard]) // eslint-disable-line react-hooks/exhaustive-deps

  const onDegenDisclaimerModalRead = () => {
    onDegenDisclaimerModalClose()
    setDegenDisclaimerModalAsShown()
    setHasDegenDisclaimerModalBeenShown(true)
  }

  return (
    <Box overflow={'hidden'} pos="relative">
      {!isHome && (
        <Box minH="100vh" position="absolute" top={0} right={0} left={0} bottom={0}>
          <Box position="absolute" top={0} right={0} left={0} bottom={0} bgColor="background.primary" />
        </Box>
      )}

      {showNetworkWarningBanner && <NetworkWarningBanner />}
      <Header isOpenDrawer={isOpenDrawer} onOpenDrawer={onOpenDrawer} onCloseDrawer={onCloseDrawer} />

      <Box minH={LAYOUT_CALC} bgColor="background.primary" position="relative">
        {children}
      </Box>

      <Footer />
      {showDegenDisclaimerBanner && <DegenDisclaimerBanner onButtonClick={onDegenDisclaimerModalOpen} />}

      {isSubieMint && !isConnected && (
        <>
          <Image
            srcSet="/assets/subies/subie-lg.png, /assets/subies/subie-lg@2x.png 2x, /assets/subies/subie-lg@3x.png 3x"
            pos="absolute"
            w={{ base: '240px', md: '500px', xl: 'initial' }}
            right={{ base: '-10%', md: -12, xl: -52, '2xl': 0 }}
            bottom={{ base: '27%', md: 12, xl: 24 }}
            zIndex={0}
          />
          <Image
            srcSet="/assets/subies/subie-md.png, /assets/subies/subie-md@2x.png 2x, /assets/subies/subie-md@3x.png 3x"
            w={{ base: '192px', xl: 'initial' }}
            pos="absolute"
            top={{ base: 6, md: 20, xl: -2 }}
            left={{ base: -20, md: 0, xl: -12 }}
            zIndex={0}
          />
          <Image
            display={{ base: 'none', xl: 'block' }}
            srcSet="/assets/subies/subie-sm.png, /assets/subies/subie-sm@2x.png 2x, /assets/subies/subie-sm@3x.png 3x"
            pos="absolute"
            left="22%"
            bottom="15%"
            zIndex={0}
          />
        </>
      )}
      <DegenDisclaimerModal
        isOpen={isDegenDisclaimerModalOpen}
        onClose={onDegenDisclaimerModalClose}
        onSubmit={onDegenDisclaimerModalRead}
        isDismissible={hasDegenDisclaimerModalBeenShown}
      />
    </Box>
  )
}

export default Layout
