import { useCallback } from 'react'
import { useAccount, useNetwork } from 'wagmi'

import { CTOKEN_DECIMALS, tokens } from '~/constants/tokens'
import * as Analytics from '~/lib/analytics'
import { DEFAULT_NETWORK_ID } from '~/lib/networks'
import { LensMarket } from '~/types'

export const useAddCTokenToWallet = (cToken?: LensMarket['cToken']): (() => Promise<boolean>) => {
  const { connector } = useAccount()
  const { chain } = useNetwork()
  const chainId = chain?.id
  const currentChainId = chainId ?? DEFAULT_NETWORK_ID
  const networkTokens = tokens[currentChainId]

  const addCTokenToWallet = useCallback(async () => {
    try {
      if (!cToken) {
        throw new Error('No cToken address to add')
      }

      await connector?.watchAsset?.({
        address: cToken,
        symbol: networkTokens[cToken].symbol,
        decimals: CTOKEN_DECIMALS,
      })

      return true
    } catch (e) {
      Analytics.trackException(e)

      return false
    }
  }, [cToken, connector, networkTokens])

  return addCTokenToWallet
}
