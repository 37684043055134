import { CERC20__factory, CETH__factory } from 'contract-types'
import { BigNumber, ContractTransaction } from 'ethers'
import { useTranslation } from 'next-i18next'
import { useSigner } from 'wagmi'

import { CustomError, getSendTransactionWithGasLimitBuffer, TransactionName } from '~/helpers'

/**
 * CToken Error Codes Mapper
 * [ErrorCode]: i18n key
 */

export const cTokenErrorCodes: { [key: string]: string } = {
  0: 'cTokenErrors.noError',
  1: 'cTokenErrors.unauthorized',
  2: 'cTokenErrors.badInput',
  3: 'cTokenErrors.comptrollerRejection',
  4: 'cTokenErrors.comptrollerCalculationError',
  5: 'cTokenErrors.interestRateModelError',
  6: 'cTokenErrors.invalidAccountPair',
  7: 'cTokenErrors.invalidCloseAmountRequested',
  8: 'cTokenErrors.invalidCollateralFactor',
  9: 'cTokenErrors.mathError',
  10: 'cTokenErrors.marketNotFresh',
  11: 'cTokenErrors.marketNotListed',
  12: 'cTokenErrors.tokenInsufficientAllowance',
  13: 'cTokenErrors.tokenInsufficientBalance',
  14: 'cTokenErrors.tokenInsufficientCash',
  15: 'cTokenErrors.tokenTransferInFailed',
  16: 'cTokenErrors.tokenTransferOutFailed',
}

/**
 * useCTokenContractWithSigner Hook
 */

export function useCTokenContractWithSigner(cTokenAddress: string, isNativeToken?: boolean) {
  const { data: signer } = useSigner()
  const { t } = useTranslation('dashboard')

  if (!signer) {
    return {}
  }

  const cTokenContract = CERC20__factory.connect(cTokenAddress, signer)
  const sendTransaction = getSendTransactionWithGasLimitBuffer(cTokenContract)

  const sendSafeTransaction = async (
    operation: TransactionName,
    // TODO: Fix params any type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: any
  ) => {
    const responseCode = (await cTokenContract.callStatic[operation](params)).toNumber()

    if (responseCode != 0) {
      throw new CustomError(t(cTokenErrorCodes[responseCode]) || t('cTokenErrors.generic'), responseCode)
    }

    return sendTransaction(operation, [params])
  }

  let borrow: (value: BigNumber) => Promise<ContractTransaction>
  let redeem: (value: BigNumber) => Promise<ContractTransaction>
  let redeemUnderlying: (value: BigNumber) => Promise<ContractTransaction>
  let mint: (value: BigNumber) => Promise<ContractTransaction>
  let repayBorrow: (value: BigNumber | string) => Promise<ContractTransaction>

  if (isNativeToken) {
    // TODO: This is done because we cannot call a native token contract function using the callStatic method
    const cNativeTokenContract = CETH__factory.connect(cTokenAddress, signer)
    const sendNativeTransaction = getSendTransactionWithGasLimitBuffer(cNativeTokenContract)
    mint = (value: BigNumber) => {
      // Does not handle errors since it reverts upon any failures
      return sendNativeTransaction('mint', [{ value }])
    }
    repayBorrow = (value: BigNumber | string) => {
      // Does not handle errors since it reverts upon any failures
      return sendNativeTransaction('repayBorrow', [{ value }])
    }
    borrow = (value) => {
      // Does not handle errors since it reverts upon any failures
      return sendNativeTransaction('borrow', [value])
    }
    redeem = (value) => {
      // Does not handle errors since it reverts upon any failures
      return sendNativeTransaction('redeem', [value])
    }
    redeemUnderlying = (value) => {
      // Does not handle errors since it reverts upon any failures
      return sendNativeTransaction('redeemUnderlying', [value])
    }
  } else {
    mint = async (value: BigNumber) => sendSafeTransaction('mint', value)
    repayBorrow = async (value: BigNumber | string) => sendSafeTransaction('repayBorrow', value)
    borrow = async (params) => sendSafeTransaction('borrow', params)
    redeem = async (params) => sendSafeTransaction('redeem', params)
    redeemUnderlying = async (params) => sendSafeTransaction('redeemUnderlying', params)
  }

  return { mint, borrow, redeem, redeemUnderlying, repayBorrow }
}
