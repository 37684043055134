import { Tooltip } from '@chakra-ui/react'

interface Props {
  children: React.ReactNode
  show: boolean
}

const TOOLTIP_LABEL = 'Claiming DEEPR is disabled during Degen phase'

const DegenClaimTooltip = ({ children, show }: Props) => {
  if (show) {
    return (
      <Tooltip label={TOOLTIP_LABEL} shouldWrapChildren hasArrow>
        {children}
      </Tooltip>
    )
  }

  return <>{children}</>
}

export default DegenClaimTooltip
