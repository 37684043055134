import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons'
import {
  chakra,
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Image,
  Link as ChakraLink,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import ClaimModal from '~/components/dashboard/ClaimModal'
import { ButtonSize, ButtonVariant, CustomButton } from '~/components/layout/CustomButton'
import { DOCS_URL, HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '~/constants'
import { getInfiniteLogoAnimation } from '~/helpers'
import { disabledFeatures } from '~/helpers/env'
import { getFaucetEnableStatus } from '~/helpers/network'
import { useDistributionToken } from '~/hooks/useDistributionToken'
import { useHydratedAccount } from '~/hooks/useHydratedAccount'
import useSupportedNetworkId from '~/hooks/useSupportedNetworkId'
import * as Analytics from '~/lib/analytics'
import { theme } from '~/styles/theme'
import { CustomConnectKitButton } from './CustomConnectKitButton'
import DegenClaimTooltip from './DegenClaimTooltip'
import HeaderButton from './HeaderButton'

interface Props {
  isOpenDrawer: boolean
  onOpenDrawer: () => void
  onCloseDrawer: () => void
}

const Header = ({ isOpenDrawer, onOpenDrawer, onCloseDrawer }: Props) => {
  const { t } = useTranslation('layout')
  const { pathname } = useRouter()

  const { distributionToken } = useDistributionToken()

  const {
    isOpen: isOpenDistributionModal,
    onOpen: onOpenDistributionModal,
    onClose: onCloseDistributionModal,
  } = useDisclosure()

  const isClaimDeeprDisabled = disabledFeatures.protocol?.claimDeepr?.disabled || disabledFeatures?.protocol?.disabled

  const openClaimModal = () => {
    onOpenDistributionModal()
    Analytics.addBreadcrumb(Analytics.BreadcrumbsTypes.CLAIM_MODAL_OPENED)
  }

  const closeClaimModal = () => {
    onCloseDistributionModal()
    Analytics.addBreadcrumb(Analytics.BreadcrumbsTypes.CLAIM_MODAL_CLOSED)
  }

  const { isConnected } = useHydratedAccount()

  const chainId = useSupportedNetworkId()

  const isHome = pathname === '/'
  const isDashboard = pathname === '/dashboard'

  const logoIsotypeStyle = getInfiniteLogoAnimation()

  const { isFaucetEnabled } = getFaucetEnableStatus(chainId)

  return (
    <chakra.header zIndex={isOpenDrawer ? 'auto' : '1'} position="relative" bgColor="background.primary">
      <Flex height={{ base: MOBILE_HEADER_HEIGHT, md: HEADER_HEIGHT }} alignItems="center">
        <Container maxW="container.xl">
          <Flex as="nav" alignItems="center" justifyContent="space-between" py={{ base: 0, md: 7 }}>
            <Link href={isDashboard ? '/dashboard' : '/'} passHref>
              <ChakraLink _hover={{ textDecoration: 'none' }} onClick={onCloseDrawer}>
                <Flex>
                  <Image
                    w="38px"
                    h="38px"
                    src="/assets/deepr-icon-white.png"
                    style={logoIsotypeStyle}
                    zIndex="popover"
                  />
                  <Image w="94px" h="38px" src="/assets/logo-text.png" zIndex="popover" />
                  <Image w="55px" h="24px" src="/assets/degen.svg" zIndex="popover" alignSelf="flex-end" ml={2} />
                </Flex>
              </ChakraLink>
            </Link>
            <HStack spacing="4" alignItems="center" display={{ base: 'none', lg: 'flex' }}>
              {!isHome && (
                <Link href="/dashboard" passHref>
                  <ChakraLink paddingX={6} fontWeight="600">
                    {t('navigation.dashboard')}
                  </ChakraLink>
                </Link>
              )}
              <Link href="/markets" passHref>
                <ChakraLink paddingX={6} fontWeight="600">
                  {t('navigation.markets')}
                </ChakraLink>
              </Link>
              <Link href="/subies" passHref>
                <ChakraLink paddingX={6} fontWeight="600">
                  {t('navigation.subieMint')}
                </ChakraLink>
              </Link>
              {isFaucetEnabled && (
                <Link href="/faucet" passHref>
                  <ChakraLink paddingX={6} fontWeight="600">
                    {t('navigation.faucet')}
                  </ChakraLink>
                </Link>
              )}

              <ChakraLink href={DOCS_URL} target="_blank" paddingX={6} fontWeight="600">
                {t('navigation.docs')}
              </ChakraLink>
              <Link href="/faq" passHref>
                <ChakraLink paddingX={6} fontWeight="600">
                  {t('navigation.faq')}
                </ChakraLink>
              </Link>
              {!isHome ? (
                <>
                  {isConnected && (
                    <DegenClaimTooltip show={!!isClaimDeeprDisabled}>
                      <HeaderButton
                        leftIcon={<Image src="/assets/deepr-icon.png" />}
                        onClick={openClaimModal}
                        isDisabled={isClaimDeeprDisabled}
                      >
                        {distributionToken?.totalBalance?.toFixed(4)}
                      </HeaderButton>
                    </DegenClaimTooltip>
                  )}
                  <CustomConnectKitButton />
                </>
              ) : (
                <Link href="/dashboard" passHref>
                  <ChakraLink _hover={{ textDecoration: 'none' }}>
                    <CustomButton variant={ButtonVariant.PRIMARY} size={ButtonSize.MEDIUM}>
                      {t('navigation.launchApp')}
                    </CustomButton>
                  </ChakraLink>
                </Link>
              )}
            </HStack>
            <HStack spacing="12px" display={{ base: 'flex', lg: 'none' }}>
              {isConnected && !isHome && (
                <DegenClaimTooltip show={!!isClaimDeeprDisabled}>
                  <HeaderButton
                    leftIcon={<Image src="/assets/deepr-icon.png" />}
                    onClick={openClaimModal}
                    height="32px"
                    fontSize="14px"
                    lineHeight="16px"
                    p="12px"
                    isDisabled={isClaimDeeprDisabled}
                  >
                    <Text maxW="74px" overflowX="clip" textOverflow="ellipsis">
                      {distributionToken?.totalBalance?.toFixed(4)}
                    </Text>
                  </HeaderButton>
                </DegenClaimTooltip>
              )}

              <IconButton
                size="sm"
                icon={
                  isOpenDrawer ? (
                    <CloseIcon boxSize={2.5} color={theme.colors.text.secondary} />
                  ) : (
                    <HamburgerIcon boxSize={6} color={theme.colors.text.secondary} />
                  )
                }
                aria-label={isOpenDrawer ? 'Close Menu' : 'Open Menu'} // TODO: Move to i18n
                onClick={isOpenDrawer ? onCloseDrawer : onOpenDrawer}
                background={theme.colors.background.box}
                zIndex="popover"
                borderWidth={isOpenDrawer ? 0 : 1}
                p={1}
                height="32px"
                borderRadius="10px"
                _hover={{}}
              />
            </HStack>
          </Flex>
        </Container>
      </Flex>

      <ClaimModal isOpen={isOpenDistributionModal} onClose={closeClaimModal} />

      <Drawer isOpen={isOpenDrawer} onClose={onCloseDrawer} size="full" placement="top">
        <DrawerOverlay />
        <DrawerContent h="full" bgColor="black">
          <DrawerBody background="radial-gradient(73.71% 73.71% at 50% 50%, rgba(33, 9, 61, 0.80) 0%, rgba(20, 6, 36, 0.80) 100%);">
            <VStack spacing={6} h="100%" justify="center">
              <Link href="/dashboard" passHref>
                <ChakraLink onClick={onCloseDrawer}>
                  <Text fontWeight="700" fontSize="xl">
                    {t('navigation.dashboard')}
                  </Text>
                </ChakraLink>
              </Link>
              <Link href="/markets" passHref>
                <ChakraLink onClick={onCloseDrawer}>
                  <Text fontWeight="700" fontSize="xl">
                    {t('navigation.markets')}
                  </Text>
                </ChakraLink>
              </Link>
              <Link href="/subies" passHref>
                <ChakraLink onClick={onCloseDrawer}>
                  <Text fontWeight="700" fontSize="xl">
                    {t('navigation.subieMint')}
                  </Text>
                </ChakraLink>
              </Link>
              {isFaucetEnabled && (
                <Link href="/faucet" passHref>
                  <ChakraLink onClick={onCloseDrawer}>
                    <Text fontWeight="700" fontSize="xl">
                      {t('navigation.faucet')}
                    </Text>
                  </ChakraLink>
                </Link>
              )}
              <ChakraLink href={DOCS_URL} target="_blank" onClick={onCloseDrawer}>
                <Text fontWeight="700" fontSize="xl">
                  {t('navigation.docs')}
                </Text>
              </ChakraLink>
              <Link href="/faq" passHref>
                <ChakraLink paddingX={6} fontWeight="600" onClick={onCloseDrawer}>
                  <Text fontWeight="700" fontSize="xl">
                    {t('navigation.faq')}
                  </Text>
                </ChakraLink>
              </Link>
              {!isHome && (
                <Flex direction="column" position="absolute" bottom="24px" w={isConnected ? 'auto' : '342px'} gap={4}>
                  <CustomConnectKitButton />
                </Flex>
              )}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </chakra.header>
  )
}

export default Header
