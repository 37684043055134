import { Box, BoxProps } from '@chakra-ui/react'

const Card = (props: BoxProps) => {
  const { children, ...rest } = props

  return (
    <Box pos="relative" {...rest}>
      <Box bgImage="/assets/noise-texture.png" bgSize="contain" pos="absolute" top={0} right={0} left={0} bottom={0} />
      <Box pos="relative">{children}</Box>
    </Box>
  )
}

export default Card
