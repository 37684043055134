import { addresses } from '~/constants/addresses'
import { SUPPORTED_NETWORKS } from '~/constants/tokens'
import { FAUCET_NETWORKS } from '~/lib/networks'
import { disabledFeatures } from './env'

export function isChainSupported(chainId?: number) {
  return (chainId && addresses[chainId]) || (typeof chainId === 'number' && SUPPORTED_NETWORKS.includes(chainId))
}

export function getFaucetEnableStatus(chainId?: number) {
  const isFaucetEnabled = !disabledFeatures?.protocol?.disabled && !disabledFeatures?.faucet?.disabled

  const networkSupportsFaucet = typeof chainId === 'number' && FAUCET_NETWORKS.includes(chainId)

  const isFaucetCaptchaEnabled = !disabledFeatures?.faucet?.captcha?.disabled

  return { networkSupportsFaucet, isFaucetEnabled, isFaucetCaptchaEnabled }
}
