import { Chain } from 'wagmi'

import { Network } from '~/lib/networks'

export const customProviders: Record<number, Chain> = {
  [Network.Shimmer]: {
    id: Network.Shimmer,
    name: 'Shimmer EVM',
    network: 'Shimmer EVM',
    rpcUrls: {
      public: {
        http: ['https://json-rpc.evm.shimmer.network/'],
      },
      default: {
        http: ['https://json-rpc.evm.shimmer.network/'],
      },
    },
    nativeCurrency: {
      decimals: 18,
      name: 'Shimmer',
      symbol: 'SMR',
    },
    blockExplorers: {
      default: {
        name: 'Shimmer Network Explorer',
        url: 'https://explorer.evm.shimmer.network/',
      },
    },
  },
  [Network.Shimmer_Testnet]: {
    id: Network.Shimmer_Testnet,
    name: 'Shimmer EVM Testnet',
    network: 'Shimmer EVM Testnet',
    rpcUrls: {
      public: {
        http: ['https://json-rpc.evm.testnet.shimmer.network/'],
      },
      default: {
        http: ['https://json-rpc.evm.testnet.shimmer.network/'],
      },
    },
    nativeCurrency: {
      decimals: 18,
      name: 'Shimmer',
      symbol: 'SMR',
    },
    testnet: true,
    blockExplorers: {
      default: {
        name: 'Shimmer Network Explorer',
        url: 'https://explorer.evm.testnet.shimmer.network/',
      },
    },
  },
}
