import { CERC20, CERC20__factory, CETH, CETH__factory } from 'contract-types'
import { BigNumber, Contract, ethers, PayableOverrides, Signer } from 'ethers'
import { intersection, isNaN } from 'lodash'

import { tokenImages } from '~/constants/tokenImages'
import { formatInputValue } from '~/helpers/dashboard'
import { LensMarket } from '~/types'

const DEFAULT_FIAT_AMOUNT_DECIMALS = 6
const DEFAULT_PERCENTAGE_DECIMALS = 2
export const DEFAULT_TOKEN_AMOUNT_DECIMALS = 4
const HIGHER_VALUE_TOKENS_AMOUNT_DECIMALS = 6
const MINIMUM_TOKEN_AMOUNT_DISPLAYED = 0.0001
const MINIMUM_PRICE_DISPLAYED = 0.01
export const GAS_LIMIT_BUFFER_MULTIPLIER = 1.25
const PAYABLE_OVERRIDES_KEYS: Array<keyof PayableOverrides> = [
  'accessList',
  'customData',
  'gasLimit',
  'gasPrice',
  'maxFeePerGas',
  'maxPriorityFeePerGas',
  'nonce',
  'type',
  'value',
]

export type TransactionName = 'borrow' | 'redeem' | 'redeemUnderlying' | 'mint' | 'repayBorrow'

interface FormatPriceNumberOptions {
  decimals?: number
  notation?: Intl.NumberFormatOptions['notation']
  showMinimumValues?: boolean
}

export const formatPriceNumberWithSymbol = (
  value: number,
  { decimals, notation, showMinimumValues }: FormatPriceNumberOptions = {
    decimals: DEFAULT_FIAT_AMOUNT_DECIMALS,
    notation: 'standard',
    showMinimumValues: true,
  }
) => {
  if (!showMinimumValues && value > 0 && value < MINIMUM_PRICE_DISPLAYED) {
    return `$ < ${MINIMUM_PRICE_DISPLAYED}`
  }

  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation,
    maximumFractionDigits: decimals ?? DEFAULT_FIAT_AMOUNT_DECIMALS,
  }).format(value)
}

export const formatPercentageString = (value: string | number, decimals = DEFAULT_PERCENTAGE_DECIMALS) => {
  if (isNaN(Number(value))) {
    return '-'
  }

  const number = typeof value === 'string' ? parseFloat(value) : value
  const percentage = number * 100

  if (percentage > 100000) {
    return `${percentage.toPrecision(1)}%`
  }

  return `${percentage.toFixed(decimals)}%`
}

export const formatTokenAmount = (
  value: number,
  symbol?: string,
  decimals = DEFAULT_TOKEN_AMOUNT_DECIMALS,
  notation: Intl.NumberFormatOptions['notation'] = 'standard'
) => {
  const formattedValue = Intl.NumberFormat('en', {
    notation,
    maximumFractionDigits:
      symbol?.includes('BTC') || symbol?.includes('ETH') ? HIGHER_VALUE_TOKENS_AMOUNT_DECIMALS : decimals,
  }).format(value)

  if (value > 0 && value < MINIMUM_TOKEN_AMOUNT_DISPLAYED) {
    return `< ${MINIMUM_TOKEN_AMOUNT_DISPLAYED} ${symbol || ''} `
  }

  return `${formattedValue} ${symbol || ''}`
}

export const getTokenImage = (underlyingSymbol?: string): string => {
  if (!underlyingSymbol) {
    return ''
  }

  return tokenImages[underlyingSymbol.toUpperCase()]
}

export const getTokenImageFullPath = (underlyingSymbol?: string): string => {
  if (!underlyingSymbol) {
    return ''
  }

  return `https://${window.location.host}${getTokenImage(underlyingSymbol)}`
}

const SECONDS_PER_DAY = 24 * 60 * 60
const DAYS_PER_YEAR = 365

export function calculateApy(ratePerSecond: number): number {
  const apy = Math.pow(ratePerSecond * SECONDS_PER_DAY + 1, DAYS_PER_YEAR) - 1

  return isNumber(apy) && isFinite(apy) ? +apy.toFixed(5) : 0
}

export function getSendTransactionWithGasLimitBuffer<T extends Contract>(contract: T) {
  async function sendTransaction<R extends keyof T, O extends Parameters<T[R]>>(action: R, params: O) {
    const estimatedGasLimit = await contract.estimateGas[action as string](...params)

    const gasLimit = Math.round(estimatedGasLimit.toNumber() * GAS_LIMIT_BUFFER_MULTIPLIER)

    const paramsWithGasLimitBuffer = [...params]

    let lastParam = paramsWithGasLimitBuffer[paramsWithGasLimitBuffer.length - 1]

    // Check if overrides params are present (always come last) and if true merge with new gasLimit
    if (
      typeof lastParam === 'object' &&
      intersection(Object.keys(lastParam as object), PAYABLE_OVERRIDES_KEYS).length
    ) {
      lastParam = { gasLimit, ...(lastParam as object) }
    } else {
      paramsWithGasLimitBuffer.push({ gasLimit })
    }

    return contract[action](...paramsWithGasLimitBuffer)
  }

  return sendTransaction
}

const GAS_FEE_CALCULATION_ENABLED = false // TODO: validate the gas price from the signer is correct before enabling it

export const estimateGasFee = async (action: TransactionName, market: LensMarket, signer?: Signer | null) => {
  if (!GAS_FEE_CALCULATION_ENABLED) {
    throw new Error(
      'Gas fee calculation is not enabled until the gas price from the signer is checked. Please check the estimateGasFee function.'
    )
  }

  if (!signer) {
    throw new Error('There is an error with the Signer')
  }

  const minValToCalculateGasFee = 1 / Math.pow(10, market.underlyingDecimals - 1)

  const value = formatInputValue(minValToCalculateGasFee, market.underlyingDecimals)

  let gasFeeUnits: BigNumber

  if (market.isNativeToken && (action === 'mint' || action === 'repayBorrow')) {
    const cNativeTokenContract: CETH = CETH__factory.connect(market.cToken, signer)

    gasFeeUnits = await cNativeTokenContract.estimateGas[action]({ value })
  } else {
    const cTokenContract: CERC20 = CERC20__factory.connect(market.cToken, signer)

    gasFeeUnits = await cTokenContract.estimateGas[action](value)
  }

  const gasPrice = await signer.getGasPrice()

  const transactionFee = gasPrice.mul(gasFeeUnits)

  return parseFloat(ethers.utils.formatUnits(transactionFee, market.underlyingDecimals))
}

export const delay = (ms: number) => new Promise<void>((resolve) => setTimeout(() => resolve(), ms))

export const timeout = async (time: number) => {
  await delay(time)

  return new Promise(() => {
    throw new Error('Timeout')
  })
}

export const isNumber = (value: unknown) => !isNaN(value)

export const getInfiniteLogoAnimation = () => ({
  animation: `spinner 4s infinite`,
  animationDirection: 'linear',
  animationDelay: '1.4s',
  transformOrigin: 'center',
})

export class CustomError extends Error {
  code: number

  data: Error | undefined = undefined

  constructor(msg: string, code: number) {
    super(msg)
    this.code = code
  }
}
