import { isMobile } from 'react-device-detect'
import { Box, Flex, Image, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'

import { CustomButton } from '../layout/CustomButton'
import CloseModalButton from './CloseModalButton'

interface Props {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  isDismissible?: boolean
}

const DegenDisclaimerModal = ({ isOpen, onClose, onSubmit, isDismissible }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={isDismissible ? onClose : () => null}
      isCentered
      size={{ base: 'full', md: 'md' }}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent minW={{ base: undefined, lg: '666px' }} maxH="434px" overflowY={{ base: 'scroll', md: 'auto' }}>
        <ModalHeader>
          <Image src="/assets/warning.svg" w="24px" h="24px" />
          <Text fontWeight="bold" fontSize="20px" lineHeight="24px" ml={1.5}>
            Important Disclaimer
          </Text>
          {isMobile && <CloseModalButton />}
        </ModalHeader>
        <ModalBody p="6" fontSize="16px" lineHeight="24px" fontWeight="medium">
          <Text>
            <Text fontWeight="bold" display="inline">
              Deepr Finance Degen
            </Text>{' '}
            is designed solely for the purpose of testing experimental features of Deepr Finance Degen and should not be
            considered as the final product. Users should be aware of the following points before using this platform:
          </Text>
          <Box pl={1.5} pt={3}>
            <Text whiteSpace="pre-line">
              1.{' '}
              <Text fontWeight="bold" display="inline">
                Financial Loss Risk:
              </Text>{' '}
              Participation in this platform carries a significant risk of financial loss. Operations and activities
              conducted in Deepr Finance Degen can result in substantial capital losses. Users should be prepared for
              the possibility of losing all or a significant portion of their funds.
            </Text>
            <Text whiteSpace="pre-line">
              {'\n'}
              2.{' '}
              <Text fontWeight="bold" display="inline">
                Experimental Platform:
              </Text>{' '}
              Deepr Finance Degen is an experimental platform and does not represent the final product of Deepr Finance.
              The markets, features, and functionalities present here do not necessarily reflect what will be available
              in the final product of Deepr Finance.
            </Text>
            <Text whiteSpace="pre-line">
              {'\n'}
              3.{' '}
              <Text fontWeight="bold" display="inline">
                Responsible Use:
              </Text>{' '}
              Users must use this platform with extreme caution and responsibility. Risky or speculative operations that
              may lead to significant financial losses are not recommended or encouraged.
            </Text>
            <Text whiteSpace="pre-line">
              {'\n'}
              4.{' '}
              <Text fontWeight="bold" display="inline">
                Minimum Age:
              </Text>{' '}
              Users must be over the age of 18 to use this platform.
            </Text>
            <Text whiteSpace="pre-line">
              {'\n'}
              5.{' '}
              <Text fontWeight="bold" display="inline">
                No Guarantees:
              </Text>{' '}
              Deepr Finance Degen and Nakama Labs do not offer any guarantees or representations regarding the security,
              reliability, or profitability of operations conducted in Deepr Finance Degen. Users assume all risks
              associated with their participation on the platform.{' '}
            </Text>
            <Text whiteSpace="pre-line">
              {'\n'}
              6.{' '}
              <Text fontWeight="bold" display="inline">
                No Liability:
              </Text>{' '}
              Deepr Finance Degen and Nakama Labs do not assume any liability for any financial loss or damage that
              users may incur as a result of using Deepr Finance Degen. Users are solely responsible for their actions
              and decisions on the platform.
            </Text>
          </Box>
          <Text pt={3} pb={3} fontWeight="bold">
            By using Deepr Finance Degen, users fully accept these terms and understand the risks associated with
            participating in an experimental DeFi platform. It is strongly recommended that users seek independent
            financial advice before engaging in any operations on this platform.
          </Text>
          <Flex alignItems="center" justifyContent="center" mt={0.5}>
            <CustomButton w="224px" h="48px" mt={6} onClick={onSubmit}>
              I have read and I agree
            </CustomButton>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default DegenDisclaimerModal
