import { isMobile } from 'react-device-detect'
import { ButtonProps } from '@chakra-ui/react'
import { useAccount } from 'wagmi'

import { ButtonSize, ButtonVariant, CustomButton } from '../layout/CustomButton'
import { CustomConnectKitButton } from '../layout/CustomConnectKitButton'

const mobileProps = { position: 'absolute', bottom: '24px', w: 'calc(100% - 32px)' } as const

const ModalButton = ({ children, ...props }: ButtonProps & { children: React.ReactNode }) => {
  const { isConnected } = useAccount()

  if (!isConnected) {
    return (
      <CustomConnectKitButton
        size={ButtonSize.LARGE}
        w="full"
        mt="33px !important"
        {...(isMobile && { ...mobileProps })}
      />
    )
  }

  return (
    <CustomButton
      {...props}
      size={ButtonSize.LARGE}
      variant={ButtonVariant.PRIMARY}
      w="full"
      type="submit"
      mt="33px !important"
      {...(isMobile && { ...mobileProps })}
    >
      {children}
    </CustomButton>
  )
}

export default ModalButton
