import { ButtonProps } from '@chakra-ui/react'
import { ConnectKitButton } from 'connectkit'
import { useTranslation } from 'next-i18next'

import * as Analytics from '~/lib/analytics'
import { ButtonSize, ButtonVariant, CustomButton } from './CustomButton'
import HeaderButton from './HeaderButton'

export const CustomConnectKitButton = ({
  size = ButtonSize.MEDIUM,
  ...styleProps
}: { size?: ButtonSize } & ButtonProps) => {
  const { t } = useTranslation('layout')

  return (
    <ConnectKitButton.Custom>
      {({ isConnected, show, truncatedAddress }) => {
        const customTruncatedAddress = truncatedAddress?.replace('••••', '...')

        const handleClick = () => {
          if (!show) {
            return
          }

          show()

          if (!isConnected) {
            Analytics.addBreadcrumb(Analytics.BreadcrumbsTypes.CONNECT_WALLET_PRESSED)
          }
        }

        return (
          <>
            {isConnected && <HeaderButton onClick={handleClick}>{customTruncatedAddress}</HeaderButton>}
            {!isConnected && (
              <CustomButton onClick={handleClick} {...styleProps} variant={ButtonVariant.PRIMARY} size={size}>
                {t('navigation.connectWallet')}
              </CustomButton>
            )}
          </>
        )
      }}
    </ConnectKitButton.Custom>
  )
}
