import { SUPPLY_EXPIRATION_LAPSE } from '~/constants/faucet'

const DEGEN_DISCLAIMER_KEY = 'deepr-degen-disclaimer-modal'

interface SuppliedAddress {
  address: string
  ip: string
  timestamp: number
}

export const checkIfWasSuppliedByFaucetToday = async (address: string, ip: string) => {
  const timestampAddress = await localStorage.getItem(`deepr-faucet-supply-timestamp-${address}`)
  const timestampIp = await localStorage.getItem(`deepr-faucet-supply-timestamp-${ip}`)

  if (!timestampAddress && !timestampIp) {
    return false
  }

  const now = Date.now()

  const wasAddressSuppliedToday = Number(timestampAddress) + SUPPLY_EXPIRATION_LAPSE > now
  const wasIpSuppliedToday = Number(timestampIp) + SUPPLY_EXPIRATION_LAPSE > now

  return wasAddressSuppliedToday || wasIpSuppliedToday
}

export const setAsSuppliedByFaucet = (data: SuppliedAddress) => {
  window.localStorage.setItem(`deepr-faucet-supply-timestamp-${data.address}`, data.timestamp.toString())
  window.localStorage.setItem(`deepr-faucet-supply-timestamp-${data.ip}`, data.timestamp.toString())
}

export const hasShownDegenDisclaimerModal = async () => localStorage.getItem(DEGEN_DISCLAIMER_KEY)

export const setDegenDisclaimerModalAsShown = () => window.localStorage.setItem(DEGEN_DISCLAIMER_KEY, 'true')
