import { isDevEnv } from '~/helpers/env'
import { Network } from '~/lib/networks'

export interface Token {
  name: string
  symbol: string
  address: string
  underlyingSymbol: string
  underlyingDecimals: number
  isNativeToken?: boolean
  isPriceAnchor?: boolean // If a market has this flag means its underlying price should be 1 USD and can be used to calculate prices at old oracle implementations
  isDistributionToken?: boolean
  supplyCapUSD?: number
  borrowCapUSD?: number
}

export const CTOKEN_DECIMALS = 8
export const UNDERLYING_PRICE_BASE_DECIMALS = 36

export const DISTRIBUTION_TOKEN_UNDERLYING_SYMBOL = 'DEEPR'

const GOERLI_TOKENS = {
  '0x20572e4c090f15667cF7378e16FaD2eA0e2f3EfF': {
    name: 'Compound ETH',
    symbol: 'cETH',
    address: '0x20572e4c090f15667cF7378e16FaD2eA0e2f3EfF',
    underlyingSymbol: 'ETH',
    underlyingDecimals: 18,
    isNativeToken: true,
  },
  '0xCCaF265E7492c0d9b7C2f0018bf6382Ba7f0148D': {
    name: 'Compound BAT',
    symbol: 'cBAT',
    address: '0xCCaF265E7492c0d9b7C2f0018bf6382Ba7f0148D',
    underlyingSymbol: 'BAT',
    underlyingDecimals: 18,
  },
  '0x822397d9a55d0fefd20F5c4bCaB33C5F65bd28Eb': {
    name: 'Compound DAI',
    symbol: 'cDAI',
    address: '0x822397d9a55d0fefd20F5c4bCaB33C5F65bd28Eb',
    underlyingSymbol: 'DAI',
    underlyingDecimals: 18,
  },
  '0xCEC4a43eBB02f9B80916F1c718338169d6d5C1F0': {
    name: 'Compound USDC',
    symbol: 'cUSDC',
    address: '0xCEC4a43eBB02f9B80916F1c718338169d6d5C1F0',
    underlyingSymbol: 'USDC',
    underlyingDecimals: 6,
    isPriceAnchor: true,
  },
  '0xA253295eC2157B8b69C44b2cb35360016DAa25b1': {
    name: 'Compound ZRX',
    symbol: 'cUSDT',
    address: '0xA253295eC2157B8b69C44b2cb35360016DAa25b1',
    underlyingSymbol: 'ZRX',
    underlyingDecimals: 18,
  },
}

const FANTOM_TESTNET_TOKENS = {
  '0xD636c38E54cacc9D5E39C5B1431fe630e207D2CD': {
    name: 'Deepr FTM',
    symbol: 'dFTM',
    address: '0xD636c38E54cacc9D5E39C5B1431fe630e207D2CD',
    underlyingSymbol: 'FTM',
    underlyingDecimals: 18,
    isNativeToken: true,
  },
  '0x39Ce8d1e1b1fCf1c740D515Ee8a3A5cABD2B5d18': {
    name: 'DEEPR',
    symbol: 'dDEEPR',
    address: '0x39Ce8d1e1b1fCf1c740D515Ee8a3A5cABD2B5d18',
    underlyingSymbol: 'DEEPR',
    underlyingDecimals: 18,
    isDistributionToken: true,
  },
  '0x2CB5Eb284316263f59516290821384Cd187ba940': {
    name: 'Deepr WMIOTA',
    symbol: 'dWMIOTA',
    address: '0x2CB5Eb284316263f59516290821384Cd187ba940',
    underlyingSymbol: 'WMIOTA',
    underlyingDecimals: 6,
  },
  '0x89c163f707abA08841682f1AfeCF4F9710586f40': {
    name: 'Deepr USDC',
    symbol: 'dUSDC',
    address: '0x89c163f707abA08841682f1AfeCF4F9710586f40',
    underlyingSymbol: 'USDC',
    underlyingDecimals: 6,
  },
  '0xFa58465669d8b3450fb54674DE6E92fbC4a61A33': {
    name: 'Deepr WBTC',
    symbol: 'dWBTC',
    address: '0xFa58465669d8b3450fb54674DE6E92fbC4a61A33',
    underlyingSymbol: 'WBTC',
    underlyingDecimals: 8,
  },
  '0x13d4a436cfC203b3fe9dCFA0587250f9De97a057': {
    name: 'Deepr WETH',
    symbol: 'dWETH',
    address: '0x13d4a436cfC203b3fe9dCFA0587250f9De97a057',
    underlyingSymbol: 'WETH',
    underlyingDecimals: 18,
  },
  '0x8DE26A3e74404df4679203Ea5d9Fbc1933c882DF': {
    name: 'Deepr USDT',
    symbol: 'dUSDT',
    address: '0x8DE26A3e74404df4679203Ea5d9Fbc1933c882DF',
    underlyingSymbol: 'USDT',
    underlyingDecimals: 6,
  },
}

const SHIMMER_TESTNET_TOKENS = {
  '0x1773009Cad8D08cd0E55AD1B2B9D9ea8957b5BFC': {
    name: 'Deepr SMR',
    symbol: 'dSMR',
    address: '0x1773009Cad8D08cd0E55AD1B2B9D9ea8957b5BFC',
    underlyingSymbol: 'SMR',
    underlyingDecimals: 18,
    isNativeToken: true,
  },
  '0x4bc539a6D5D48c30a376bbDd15E9545F30B914a7': {
    name: 'Deepr DEEPR',
    symbol: 'dDEEPR',
    address: '0x4bc539a6D5D48c30a376bbDd15E9545F30B914a7',
    underlyingSymbol: 'DEEPR',
    underlyingDecimals: 18,
    isDistributionToken: true,
  },
  '0x121a7b29A519253fdDe72453b4C08176B2F1f424': {
    name: 'Deepr Wen',
    symbol: 'dWEN',
    address: '0x121a7b29A519253fdDe72453b4C08176B2F1f424',
    underlyingSymbol: 'WEN',
    underlyingDecimals: 0,
    supplyCapUSD: 30,
  },
  '0x2b031472d82734d384331b79d56944Cb7f468cdD': {
    name: 'Deepr FISH',
    symbol: 'dFISH',
    address: '0x2b031472d82734d384331b79d56944Cb7f468cdD',
    underlyingSymbol: 'FISH',
    underlyingDecimals: 18,
    supplyCapUSD: 30,
  },
}
const SHIMMER_MAINNET_TOKENS = {
  '0xFb7972C9D09d3949E7f5Cc6040b188050f2fd657': {
    name: 'Deepr SMR',
    symbol: 'dSMR',
    address: '0xFb7972C9D09d3949E7f5Cc6040b188050f2fd657',
    underlyingSymbol: 'SMR',
    underlyingDecimals: 18,
    isNativeToken: true,
  },
  '0x1582C0FE5fC95a4047dFd2dAfb9062cCa4939fCf': {
    name: 'Deepr DEEPR',
    symbol: 'dDEEPR',
    address: '0x1582C0FE5fC95a4047dFd2dAfb9062cCa4939fCf',
    underlyingSymbol: 'DEEPR',
    underlyingDecimals: 18,
    isDistributionToken: true,
  },
  '0xB4e1A2E19856e35304c602d74a640734ea228154': {
    name: 'Deepr Wen',
    symbol: 'dWEN',
    address: '0xB4e1A2E19856e35304c602d74a640734ea228154',
    underlyingSymbol: 'WEN',
    underlyingDecimals: 0,
    supplyCapUSD: 30,
  },
  '0xFE6F36270d323aF0aB4Cf5C6809f49ba69f52d72': {
    name: 'Deepr FISH',
    symbol: 'dFISH',
    address: '0xFE6F36270d323aF0aB4Cf5C6809f49ba69f52d72',
    underlyingSymbol: 'FISH',
    underlyingDecimals: 18,
    supplyCapUSD: 30,
  },
}

export const tokens: {
  [key: number]: {
    [key: string]: Token
  }
} = {
  [Network.Shimmer_Testnet]: SHIMMER_TESTNET_TOKENS,
  [Network.Shimmer]: SHIMMER_MAINNET_TOKENS,
}

if (isDevEnv) {
  tokens[Network.Goerli] = GOERLI_TOKENS
  tokens[Network.Fantom_Testnet] = FANTOM_TESTNET_TOKENS
}

export const SUPPORTED_NETWORKS = Object.keys(tokens).map((key) => Number(key) as Network)
