import { Provider } from '@wagmi/core'
import { CompoundLens, CompoundLens__factory } from 'contract-types'
import { formatUnits } from 'ethers/lib/utils'
import { Dictionary, find, keyBy, map } from 'lodash'
import useSWR, { SWRResponse } from 'swr'
import { useAccount, useProvider } from 'wagmi'

import { addresses } from '~/constants/addresses'
import { tokens, UNDERLYING_PRICE_BASE_DECIMALS } from '~/constants/tokens'
import useSupportedNetworkId from './useSupportedNetworkId'

/**
 * Types
 */

type CTokenUnderlyingPriceMapType = Dictionary<{ cToken: string; underlyingPrice: number }>
type CTokenUnderlyingPriceAllType = Awaited<ReturnType<CompoundLens['callStatic']['cTokenUnderlyingPriceAll']>>
type AccountLimitsType = Awaited<ReturnType<CompoundLens['callStatic']['getAccountLimits']>>

/**
 * Constants
 */

const REFRESH_INTERVAL = 13000

/**
 * Helpers
 */

function formatUnderlyingPrices(
  chainId: number,
  cTokenUnderlyingPriceAll?: CTokenUnderlyingPriceAllType,
  accountLimits?: AccountLimitsType
): { cTokenUnderlyingPriceMap: CTokenUnderlyingPriceMapType; accountLiquidity: number } {
  const networkTokens = tokens[chainId]

  const cTokenUnderlyingPriceFormatted = map(
    cTokenUnderlyingPriceAll,
    ({ cToken, underlyingPrice: underlyingPriceBigNumber }) => {
      const decimals = networkTokens[cToken]?.underlyingDecimals

      if (decimals === undefined) {
        return { cToken, underlyingPrice: -1 }
      }

      return {
        cToken,
        underlyingPrice: +formatUnits(underlyingPriceBigNumber, UNDERLYING_PRICE_BASE_DECIMALS - decimals),
      }
    }
  ).filter(({ underlyingPrice }) => underlyingPrice >= 0)

  const cTokenUnderlyingPriceMap = keyBy(cTokenUnderlyingPriceFormatted, 'cToken')
  const accountLiquidity = +formatUnits(accountLimits?.liquidity ?? 0)

  const nativeToken = find(networkTokens, { isNativeToken: true })

  if (!nativeToken) return { cTokenUnderlyingPriceMap, accountLiquidity }

  const nativeTokenPrice = cTokenUnderlyingPriceMap[nativeToken.address]?.underlyingPrice

  // Checks if retrieved underlying prices are measured in Native Token unit
  if (nativeTokenPrice === 1) {
    const anchorToken = find(networkTokens, { isPriceAnchor: true })

    if (!anchorToken) return { cTokenUnderlyingPriceMap, accountLiquidity }

    const anchorTokenPrice = cTokenUnderlyingPriceMap[anchorToken.address].underlyingPrice

    const nativeTokenPriceUSD = nativeTokenPrice / anchorTokenPrice

    const cTokenUnderlyingPriceAllOldOracle = cTokenUnderlyingPriceFormatted?.map(
      ({ cToken, underlyingPrice: underlyingPriceRaw }) => ({
        cToken,
        underlyingPrice: underlyingPriceRaw * nativeTokenPriceUSD,
      })
    )

    return {
      cTokenUnderlyingPriceMap: keyBy(cTokenUnderlyingPriceAllOldOracle, 'cToken'),
      accountLiquidity: accountLiquidity * nativeTokenPriceUSD,
    }
  }

  return { cTokenUnderlyingPriceMap, accountLiquidity }
}

/**
 * Fetcher
 */

const fetcher = async (chainId: number, provider: Provider, account?: string) => {
  const addressesByNetwork = addresses[chainId]
  const compoundLensContract = CompoundLens__factory.connect(addressesByNetwork.lens, provider)

  const allMarkets = map(tokens[chainId], 'address')

  const [cTokenUnderlyingPriceAll, accountLimits] = await Promise.all([
    compoundLensContract.callStatic.cTokenUnderlyingPriceAll(allMarkets),
    account ? compoundLensContract.callStatic.getAccountLimits(addressesByNetwork.comptroller, account) : undefined,
  ])

  return { cTokenUnderlyingPriceAll, accountLimits, allMarkets }
}

/**
 * useUnderlyingPriceData Hook
 */

export const useUnderlyingPriceData = (): {
  cTokenUnderlyingPriceMap: CTokenUnderlyingPriceMapType
  accountLiquidity: number
  accountLimits?: AccountLimitsType
  isLoading: boolean
  error: SWRResponse['error']
  refetch: () => void
} => {
  const { address } = useAccount()
  const currentChainId = useSupportedNetworkId()

  const provider = useProvider({ chainId: currentChainId })

  const { data, mutate, error } = useSWR(
    `priceData-${currentChainId}${address || ''}`,
    () => fetcher(currentChainId, provider, address),
    {
      refreshInterval: REFRESH_INTERVAL,
    }
  )

  const { cTokenUnderlyingPriceMap, accountLiquidity } = formatUnderlyingPrices(
    currentChainId,
    data?.cTokenUnderlyingPriceAll,
    data?.accountLimits
  )

  return {
    cTokenUnderlyingPriceMap,
    accountLiquidity,
    accountLimits: data?.accountLimits,
    refetch: mutate,
    isLoading: !data && !error,
    error,
  }
}
