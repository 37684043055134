import { Comptroller, Comptroller__factory } from 'contract-types'
import { useTranslation } from 'next-i18next'
import { useSigner } from 'wagmi'

import { addresses } from '~/constants/addresses'
import { CustomError, getSendTransactionWithGasLimitBuffer } from '~/helpers'
import useSupportedNetworkId from './useSupportedNetworkId'

/**
 * Comptroller Error Codes Mapper
 * [ErrorCode]: i18n key
 */

export const comptrollerErrorCodes: { [key: string]: string } = {
  0: 'comptrollerErrors.noError',
  1: 'comptrollerErrors.unauthorized',
  2: 'comptrollerErrors.comptrollerMismatch',
  3: 'comptrollerErrors.insufficientShortfall',
  4: 'comptrollerErrors.insufficientLiquidity',
  5: 'comptrollerErrors.invalidCloseFactor',
  6: 'comptrollerErrors.invalidCollateralFactor',
  7: 'comptrollerErrors.invalidLiquidationIncentive',
  8: 'comptrollerErrors.marketNotEntered',
  9: 'comptrollerErrors.marketNotListed',
  10: 'comptrollerErrors.marketAlreadyListed',
  11: 'comptrollerErrors.mathError',
  12: 'comptrollerErrors.nonzeroBorrowBalance',
  13: 'comptrollerErrors.priceError',
  14: 'comptrollerErrors.rejection',
  15: 'comptrollerErrors.snapshotError',
  16: 'comptrollerErrors.tooManyAssets',
  17: 'comptrollerErrors.tooMuchRepay',
}

/**
 * useComptrollerContractWithSigner Hook
 */

export function useComptrollerContractWithSigner() {
  const { data: signer } = useSigner()
  const currentChainId = useSupportedNetworkId()

  const comptrollerAddress = addresses[currentChainId]?.comptroller
  const { t } = useTranslation('dashboard')

  if (!signer || !comptrollerAddress) {
    return {}
  }

  const comptrollerContract = Comptroller__factory.connect(comptrollerAddress, signer)

  const sendTransaction = getSendTransactionWithGasLimitBuffer(comptrollerContract)

  const enterMarkets: Comptroller['enterMarkets'] = async (params) => {
    const responseCode = (await comptrollerContract.callStatic.enterMarkets(params))[0].toNumber()

    if (responseCode != 0) {
      throw new CustomError(t(comptrollerErrorCodes[responseCode]) || t('comptrollerErrors.generic'), responseCode)
    }

    return sendTransaction('enterMarkets', [params])
  }

  const exitMarket: Comptroller['exitMarket'] = async (params) => {
    const responseCode = (await comptrollerContract.callStatic.exitMarket(params)).toNumber()

    if (responseCode != 0) {
      throw new CustomError(t(comptrollerErrorCodes[responseCode]) || t('comptrollerErrors.generic'), responseCode)
    }

    return sendTransaction('exitMarket', [params])
  }

  const claimDeepr: Comptroller['claimDeepr(address)'] = (params) => {
    // Does not handle errors since it reverts upon any failures
    return sendTransaction('claimDeepr(address)', [params])
  }

  return { enterMarkets, exitMarket, claimDeepr }
}
