import { useAccount } from 'wagmi'

import { useComptrollerContractWithSigner } from '~/hooks/useComptrollerContractWithSigner'
import { useDistributionToken } from '~/hooks/useDistributionToken'
import { TransactionStatus, useTransactionStatus } from '~/hooks/useTransactionStatus'
import * as Analytics from '~/lib/analytics'
import { useMarket } from './useMarket'

/**
 * UseClaimModal Hook
 */

export function useClaimModal() {
  const { distributionToken, refetch } = useDistributionToken()
  const { transactionStatus, setTransactionStatus, closeAllToasts } = useTransactionStatus()
  const { claimDeepr } = useComptrollerContractWithSigner()
  const { market } = useMarket(distributionToken?.address)
  const { underlyingSymbol, underlyingDecimals, underlyingToken, underlyingPriceFIAT } = market || {}

  const { address } = useAccount()

  const isPending = transactionStatus === TransactionStatus.SIGN
  const isBroadcasting = transactionStatus === TransactionStatus.BROADCAST

  const claim = async () => {
    Analytics.addBreadcrumb(Analytics.BreadcrumbsTypes.CLAIM_ATTEMPTED)

    setTransactionStatus(null)
    closeAllToasts()

    if (!address) {
      return false
    }

    try {
      if (!claimDeepr) {
        throw new Error('Missing signer')
      }

      setTransactionStatus(TransactionStatus.SIGN)

      const tx = await claimDeepr(address)

      setTransactionStatus(TransactionStatus.BROADCAST)

      await tx.wait()

      setTransactionStatus(TransactionStatus.SUCCESS)
      refetch()

      return true
    } catch (e) {
      setTimeout(() => setTransactionStatus(TransactionStatus.FAILED, e), 300)
      Analytics.trackException(e)

      return false
    }
  }

  return {
    claim,
    isPending,
    isBroadcasting,
    distributionToken: {
      ...distributionToken,
      underlyingSymbol,
      underlyingDecimals,
      underlyingTokenAddress: underlyingToken,
    },
    distributionTokenPrice: underlyingPriceFIAT,
  }
}
