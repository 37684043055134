import { getDefaultClient } from 'connectkit'
import { values } from 'lodash'
import { configureChains, createClient } from 'wagmi'
import { fantomTestnet } from 'wagmi/chains'
import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public'

import { customProviders } from '~/constants/custom-providers'
import { isDevEnv } from '~/helpers/env'

const infuraId = process.env.INFURA_KEY || ''

const defaultChains = isDevEnv ? [fantomTestnet] : []

const { provider, chains } = configureChains(
  [...values(customProviders), ...defaultChains],
  [infuraProvider({ apiKey: infuraId }), publicProvider()]
)

export const client = createClient(
  getDefaultClient({
    // TODO: This could be set dynamically per environment
    appName: '[DEV] Deepr',
    chains,
    provider,
  })
)
