import { Network } from '~/lib/networks'

export const addresses: {
  [key: number]: {
    comptroller: string // Always use the Unitroller address here, not comptroller
    lens: string
    maximillion?: string
    faucet?: string
    subieMintToken?: string
    subieMinter?: string
    liquidate?: string
  }
} = {
  [Network.Goerli]: {
    comptroller: '0x627ea49279fd0de89186a58b8758ad02b6be2867',
    lens: '0x04EC9f6Ce8ca39Ee5c7ADE95C69e38ddcaA8CbB7',
  },
  [Network.Fantom_Testnet]: {
    comptroller: '0x09BC6d6543D71600f2C11600841CE7E88C264EEC',
    lens: '0xD4F004891cea2BbEcC4B5A596915DE54D0fc00a2',
    maximillion: '0x4fD33b7c604Af033Efd66c85d33686CC2Ee3a43A',
  },
  [Network.Shimmer_Testnet]: {
    comptroller: '0x7A6D7bDF5683705a268E958b1Ab292B1472a8810',
    lens: '0xc6ce3aD7A64Fa6b2A6Ab900f1B5285eb0575ed5C',
    maximillion: '0xD470e93C69AA87f8113b5a8A87d5b6f94f6e4DE6',
    faucet: '0xA56C09640e20C07069fCa97Cb88fFa30e4167142',
    subieMintToken: '0xA0E1A63E39D2C97d93c79115234c4cdFE6f33067',
    subieMinter: '0x24b7b369CCB384E451c9bcBF41dE4C74eC21F43C',
    liquidate: '0x628515BF7551a6Cd71ab9B60b83B199AE3b0f616',
  },
  [Network.Shimmer]: {
    comptroller: '0x87fa4d93BdbB2aC71aB574768b2a6D2D33208aC5',
    lens: '0x660d12D4efd80F4f843434d1A9168f743025Cd35',
    maximillion: '0x80618337eaAe295560f4b163B5F6F28FC94A91E9',
    subieMintToken: '0x6E0e6A580C96d42A4a3768bF59871EE9b423B053',
    subieMinter: '0x501221B18ee951725f76f654a7c66007043070f1',
    liquidate: '0x628515BF7551a6Cd71ab9B60b83B199AE3b0f616',
  },
}
