import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Button, CloseButton, Container, Flex, HStack, Image, Text } from '@chakra-ui/react'

interface Props {
  onButtonClick: () => void
}

const TEXTS = [
  'Deepr Finance Degen involves lending and borrowing memecoins, which are highly volatile.',
  'Make informed decisions.',
]

const BUTTON_TEXT = 'Learn More'

const DegenDisclaimerBanner = ({ onButtonClick }: Props) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => setIsVisible(true), [])

  const onDismiss = () => setIsVisible(false)

  if (!isVisible) {
    return <></>
  }

  return (
    <Flex
      bgColor="degen.D"
      position="sticky"
      bottom={0}
      h={isMobile ? '92px' : '88px'}
      alignItems="center"
      pl={{ base: 0, md: 2 }}
      py={isMobile ? 4 : 6}
      zIndex={2}
    >
      <Container maxW="container.xl">
        <Flex justifyContent="space-between" alignItems="center">
          <HStack alignItems="center">
            {!isMobile && <Image src="/assets/warning.svg" w="24px" h="24px" mr={4} />}

            {isMobile ? (
              <Text fontSize="16px" fontWeight="medium" lineHeight="20px">
                {TEXTS[0]} {TEXTS[1]}{' '}
                <Button
                  onClick={onButtonClick}
                  textDecoration="underline"
                  fontWeight="bold"
                  variant="link"
                  color="text.primary"
                >
                  {BUTTON_TEXT}
                </Button>
              </Text>
            ) : (
              <Flex direction="column">
                <Text fontSize="16px" fontWeight="medium" lineHeight="20px">
                  {TEXTS[0]}
                </Text>

                <Text fontSize="16px" fontWeight="medium" lineHeight="20px">
                  {TEXTS[1]}{' '}
                  <Button
                    onClick={onButtonClick}
                    textDecoration="underline"
                    fontWeight="bold"
                    variant="link"
                    color="text.primary"
                  >
                    {BUTTON_TEXT}
                  </Button>
                </Text>
              </Flex>
            )}
          </HStack>

          <CloseButton alignSelf={{ base: 'flex-start', md: undefined }} onClick={onDismiss} size="sm" />
        </Flex>
      </Container>
    </Flex>
  )
}

export default DegenDisclaimerBanner
