import { Box, Container, Flex, Image, Link as ChakraLink } from '@chakra-ui/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import Divider from '~/components/common/Divider'
import { DOCS_URL } from '~/constants'
import { getInfiniteLogoAnimation } from '~/helpers'
import { getFaucetEnableStatus } from '~/helpers/network'
import useSupportedNetworkId from '~/hooks/useSupportedNetworkId'
import { theme } from '~/styles/theme'
import Card from '../common/Card'
import SocialLinks from './SocialLinks'

const Footer = () => {
  const { t } = useTranslation('layout')

  const { pathname } = useRouter()

  const logoIsotypeStyle = getInfiniteLogoAnimation()

  const chainId = useSupportedNetworkId()
  const { isFaucetEnabled } = getFaucetEnableStatus(chainId)

  const isHome = pathname === '/'

  return (
    <Card>
      <Box
        backdropFilter="blur(16px)"
        zIndex={1}
        w="100%"
        as="footer"
        background={theme.colors.background.box}
        borderTop="1px solid #FFFFFF12"
        pos={{ base: 'relative', md: isHome ? 'absolute' : 'relative' }}
        bottom={0}
      >
        <Container maxW="container.xl">
          <Flex
            flexDir={{ base: 'column', md: 'row' }}
            gap={{ base: '6', md: '0' }}
            alignItems="center"
            justifyContent="space-between"
            py={5}
          >
            <Flex as="nav" flexDir={{ base: 'column', md: 'row' }} alignItems="center">
              <Link href="/" passHref>
                <ChakraLink
                  _hover={{ textDecoration: 'none' }}
                  mr={{ base: '0', md: '10' }}
                  mb={{ base: '6', md: '0' }}
                >
                  <Image w={8} h={8} src="/assets/deepr-icon-white.png" style={logoIsotypeStyle} />
                </ChakraLink>
              </Link>
              <Flex
                gap={{ base: '4', md: '12' }}
                pl={{ md: '4' }}
                flexDirection={{ base: 'column', md: 'row' }}
                alignItems="center"
              >
                <Link href="/markets" passHref>
                  <ChakraLink fontSize="sm" color="text.secondary">
                    {t('navigation.markets')}
                  </ChakraLink>
                </Link>
                {isFaucetEnabled && (
                  <Link href="/faucet" passHref>
                    <ChakraLink fontSize="sm" color="text.secondary">
                      {t('navigation.faucet')}
                    </ChakraLink>
                  </Link>
                )}
                <ChakraLink href={DOCS_URL} target="_blank" fontSize="sm" color="text.secondary">
                  {t('navigation.docs')}
                </ChakraLink>
                <Link href="/terms" passHref>
                  <ChakraLink fontSize="sm" color="text.secondary">
                    {t('navigation.terms')}
                  </ChakraLink>
                </Link>
                <Link href="/faq" passHref>
                  <ChakraLink fontSize="sm" color="text.secondary">
                    {t('navigation.faq')}
                  </ChakraLink>
                </Link>
              </Flex>
            </Flex>
            <Divider display={{ md: 'none' }} />
            <SocialLinks />
          </Flex>
        </Container>
      </Box>
    </Card>
  )
}

export default Footer
