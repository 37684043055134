import * as Sentry from '@sentry/nextjs'
import { Breadcrumb } from '@sentry/nextjs'
import { capitalize } from 'lodash'

export const trackException = (e: Error | unknown) => {
  if (e instanceof Error) {
    Sentry.captureException(e)
  }
}

type LabelVars = {
  [key: string]: string | undefined
}

export enum BreadcrumbsTypes {
  MODAL_OPENED = 'MODAL_OPENED',
  MODAL_CLOSED = 'MODAL_CLOSED',
  CLAIM_MODAL_OPENED = 'CLAIM_MODAL_OPENED',
  CLAIM_MODAL_CLOSED = 'CLAIM_MODAL_CLOSED',
  CLAIM_ATTEMPTED = 'CLAIM_ATTEMPTED',
  MAX_BUTTON_CLICKED = 'MAX_BUTTON_CLICKED',
  LIMIT_BUTTON_CLICKED = 'LIMIT_BUTTON_CLICKED',
  BORROW_ROW_CLICKED = 'BORROW_ROW_CLICKED',
  SUPPLY_ROW_CLICKED = 'SUPPLY_ROW_CLICKED',
  COLLATERALIZE_TOGGLED = 'COLLATERALIZE_TOGGLED',
  CONNECT_WALLET_PRESSED = 'CONNECT_WALLET_PRESSED',
  LIGHTHOUSE_LOADED = 'LIGHTHOUSE_LOADED',
  TOGGLED_LABEL = 'TOGGLED_LABEL',
  COLLATERALIZE_ATTEMPTED = 'COLLATERALIZE_ATTEMPTED',
  UNCOLLATERALIZE_ATTEMPTED = 'UNCOLLATERALIZE_ATTEMPTED',
  COLLATERALIZED_SUCCESSFULLY = 'COLLATERALIZED_SUCCESSFULLY',
  BORROW_ATTEMPTED = 'BORROW_ATTEMPTED',
  BORROWED_SUCCESSFULLY = 'BORROWED_SUCCESSFULLY',
  REPAY_ATTEMPTED = 'REPAY_ATTEMPTED',
  REPAID_SUCCESSFULLY = 'REPAID_SUCCESSFULLY',
  APPROVE_ATTEMPTED = 'APPROVE_ATTEMPTED',
  APPROVED_SUCCESSFULLY = 'APPROVED_SUCCESSFULLY',
  SUPPLY_ATTEMPTED = 'SUPPLY_ATTEMPTED',
  SUPPLIED_SUCCESSFULLY = 'SUPPLIED_SUCCESSFULLY',
  WITHDRAW_ATTEMPTED = 'WITHDRAW_ATTEMPTED',
  WITHDREW_SUCCESSFULLY = 'WITHDREW_SUCCESSFULLY',
  ADD_CTOKEN_ATTEMPTED = 'ADD_CTOKEN_ATTEMPTED',
  CTOKEN_ADDED_SUCCESSFULLY = 'CTOKEN_ADDED_SUCCESSFULLY',
  SHOWED_TOAST_SUCCESS = 'SHOWED_TOAST_SUCCESS',
  SHOWED_TOAST_ERROR = 'SHOWED_TOAST_ERROR',
  DRIP_FAUCET_TOKENS_ATTEMPTED = 'DRIP_FAUCET_TOKENS_ATTEMPTED',
  FAUCET_SUCCESSFUL = 'FAUCET_SUCCESSFUL',
  FAUCET_ERROR = 'FAUCET_ERROR',
  FETCH_USER_IP_ATTEMPTED = 'FETCH_USER_IP_ATTEMPTED',
}

const breadcrumbMessages = {
  [BreadcrumbsTypes.MODAL_OPENED]: (vars: LabelVars) =>
    `${capitalize(vars.selectedMode)} modal opened for ${vars.underlyingSymbol}`,

  [BreadcrumbsTypes.LIMIT_BUTTON_CLICKED]: (vars: LabelVars) => `${vars.borrowLimitCap} limit button clicked`,
  [BreadcrumbsTypes.BORROW_ROW_CLICKED]: (vars: LabelVars) => `Borrow row ${vars.underlyingSymbol} clicked`,
  [BreadcrumbsTypes.SUPPLY_ROW_CLICKED]: (vars: LabelVars) => `Supply row ${vars.underlyingSymbol} clicked`,
  [BreadcrumbsTypes.COLLATERALIZE_TOGGLED]: (vars: LabelVars) => `Collateral ${vars.underlyingSymbol} toggled`,
  [BreadcrumbsTypes.TOGGLED_LABEL]: (vars: LabelVars) => `Toggled to ${vars.activeMode}`,
  [BreadcrumbsTypes.COLLATERALIZE_ATTEMPTED]: (vars: LabelVars) =>
    `Attempted to collateralize ${vars.underlyingSymbol}`,
  [BreadcrumbsTypes.UNCOLLATERALIZE_ATTEMPTED]: (vars: LabelVars) =>
    `Attempted to uncollateralize ${vars.underlyingSymbol}`,
  [BreadcrumbsTypes.BORROW_ATTEMPTED]: (vars: LabelVars) =>
    `Attempted to borrow ${vars.amount} ${vars.underlyingSymbol}`,
  [BreadcrumbsTypes.REPAY_ATTEMPTED]: (vars: LabelVars) => `Attempted to repay ${vars.amount} ${vars.underlyingSymbol}`,
  [BreadcrumbsTypes.APPROVE_ATTEMPTED]: (vars: LabelVars) => `Attempted to approve ${vars.underlyingSymbol}`,
  [BreadcrumbsTypes.SUPPLY_ATTEMPTED]: (vars: LabelVars) =>
    `Attempted to supply ${vars.amount} ${vars.underlyingSymbol}`,
  [BreadcrumbsTypes.WITHDRAW_ATTEMPTED]: (vars: LabelVars) =>
    `Attempted to withdraw ${vars.amount} ${vars.underlyingSymbol}`,
  [BreadcrumbsTypes.WITHDREW_SUCCESSFULLY]: 'Withdrawed successfully',
  [BreadcrumbsTypes.COLLATERALIZED_SUCCESSFULLY]: 'Collateralized successfully',
  [BreadcrumbsTypes.SUPPLIED_SUCCESSFULLY]: 'Supplied successfully',
  [BreadcrumbsTypes.APPROVED_SUCCESSFULLY]: 'Approved successfully',
  [BreadcrumbsTypes.REPAID_SUCCESSFULLY]: 'Repaid successfully',
  [BreadcrumbsTypes.BORROWED_SUCCESSFULLY]: 'Borrowed successfully',
  [BreadcrumbsTypes.LIGHTHOUSE_LOADED]: 'Lighthouse loaded',
  [BreadcrumbsTypes.CONNECT_WALLET_PRESSED]: 'Connect wallet button pressed',
  [BreadcrumbsTypes.MAX_BUTTON_CLICKED]: 'Max button clicked',
  [BreadcrumbsTypes.MODAL_CLOSED]: 'Modal closed',
  [BreadcrumbsTypes.ADD_CTOKEN_ATTEMPTED]: 'Attempted to add cToken to wallet',
  [BreadcrumbsTypes.CTOKEN_ADDED_SUCCESSFULLY]: 'Added cToken successfully',
  [BreadcrumbsTypes.SHOWED_TOAST_SUCCESS]: 'Showed success toast',
  [BreadcrumbsTypes.SHOWED_TOAST_ERROR]: 'Showed error toast',
  [BreadcrumbsTypes.DRIP_FAUCET_TOKENS_ATTEMPTED]: 'Drip tokens pressed',
  [BreadcrumbsTypes.FAUCET_SUCCESSFUL]: 'Faucet successful',
  [BreadcrumbsTypes.FAUCET_ERROR]: 'Faucet error',
  [BreadcrumbsTypes.FETCH_USER_IP_ATTEMPTED]: 'Attempted to fetch ip',
  [BreadcrumbsTypes.CLAIM_MODAL_OPENED]: 'Claim modal opened',
  [BreadcrumbsTypes.CLAIM_MODAL_CLOSED]: 'Claim modal closed',
  [BreadcrumbsTypes.CLAIM_ATTEMPTED]: 'Attempted to claim DEEPR',
}

export const addBreadcrumb = (type: BreadcrumbsTypes, vars?: LabelVars, data?: Breadcrumb['data'], level = 'info') => {
  const breadcrumbMessage = breadcrumbMessages[type]

  const message = typeof breadcrumbMessage === 'function' ? breadcrumbMessage(vars as LabelVars) : breadcrumbMessage

  Sentry.addBreadcrumb({
    message,
    data,
    category: 'custom',
    level: level as Sentry.Severity,
  })
}
