import { useEffect, useState } from 'react'
import { Image, ModalBody, ModalCloseButton, ModalHeader, Stack, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

import Recaptcha from '../common/Recaptcha'
import CloseModalButton from './CloseModalButton'
import ModalButton from './ModalButton'

interface Props {
  isBroadcasting: boolean
  onClose: () => void
  onSubmit?: (token?: string) => void
  withCaptcha?: boolean
  autoSubmit?: boolean
}

const PendingTransaction = ({ isBroadcasting, onClose, onSubmit, withCaptcha, autoSubmit }: Props) => {
  const { t } = useTranslation('dashboard')

  const [isCaptchaValid, setCaptchaValid] = useState(false)

  const [showCaptcha, setShowCaptcha] = useState(withCaptcha && !!onSubmit)

  useEffect(() => {
    if (autoSubmit && onSubmit) {
      onSubmit()
    }
  }, [autoSubmit]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCaptchaVerify = (token: string) => {
    if (!onSubmit) {
      return
    }

    setCaptchaValid(true)
    onSubmit(token)
  }

  const onCaptchaValidated = () => setShowCaptcha(false)

  if (showCaptcha) {
    return (
      <>
        <ModalHeader>
          {t('captcha.header')}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody p={10}>
          <Stack spacing="16px" alignItems="center">
            <Text fontSize={17} pb="8px" color="text.secondary">
              {t('transactionStatus.captcha.title')}
            </Text>
            <Recaptcha onVerify={handleCaptchaVerify} />
            <ModalButton isDisabled={!isCaptchaValid} onClick={onCaptchaValidated}>
              {t('captcha.button')}
            </ModalButton>
          </Stack>
        </ModalBody>
      </>
    )
  }

  return (
    <>
      <ModalHeader fontSize={{ base: '16px', md: '20px' }}>
        {t('pendingTransaction')}
        <CloseModalButton />
      </ModalHeader>
      <ModalBody p={10} display="flex" justifyContent="center" alignItems="center">
        <Stack spacing="16px" alignItems="center" w="full">
          <Image
            sx={{
              animation: 'spinner 2s infinite linear',
            }}
            w={20}
            h={20}
            src="/assets/deepr-icon-white.png"
          />
          <Text pt="8px" color="text.secondary" textAlign="center">
            {isBroadcasting ? t('transactionStatus.broadcast.title') : t('transactionStatus.sign.title')}
          </Text>

          {isBroadcasting && <ModalButton onClick={onClose}>{t('dismiss')}</ModalButton>}
        </Stack>
      </ModalBody>
    </>
  )
}

export default PendingTransaction
