import { useMemo } from 'react'
import { Provider } from '@wagmi/core'
import { CompoundLens__factory } from 'contract-types'
import { filter, find, map } from 'lodash'
import useSWR from 'swr'
import { useAccount, useProvider } from 'wagmi'

import { addresses } from '~/constants/addresses'
import { tokens } from '~/constants/tokens'
import { getBalancesMap, getEnrichedMarkets, getLighthouseData } from '~/helpers/dashboard'
import { disabledFeatures } from '~/helpers/env'
import useSupportedNetworkId from './useSupportedNetworkId'
import { useUnderlyingPriceData } from './useUnderlyingPriceData'

/**
 * Constants
 */

const REFRESH_INTERVAL = 13000

/**
 * Fetcher
 */

const fetcher = async (chainId: number, provider: Provider, account?: string) => {
  const addressesByNetwork = addresses[chainId]
  const compoundLensContract = CompoundLens__factory.connect(addressesByNetwork.lens, provider)

  const allMarkets = map(tokens[chainId], 'address')

  const [cTokenMetadataAll, cTokenBalancesAll] = await Promise.all([
    compoundLensContract.callStatic.cTokenMetadataAll(allMarkets),
    account ? compoundLensContract.callStatic.cTokenBalancesAll(allMarkets, account) : undefined,
  ])

  return { cTokenMetadataAll, cTokenBalancesAll, allMarkets }
}

/**
 * useMarkets Hook
 */

export const useMarkets = () => {
  const { address } = useAccount()

  const currentChainId = useSupportedNetworkId()

  const provider = useProvider({ chainId: currentChainId })
  const { cTokenUnderlyingPriceMap, accountLiquidity, accountLimits } = useUnderlyingPriceData()

  const { data, mutate, error } = useSWR(
    `${currentChainId}${address || 'markets'}`,
    () => fetcher(currentChainId, provider, address),
    {
      refreshInterval: REFRESH_INTERVAL,
    }
  )

  const { cTokenMetadataAll, cTokenBalancesAll, allMarkets } = data || {}

  const balancesMap = useMemo(
    () =>
      cTokenMetadataAll
        ? getBalancesMap(cTokenMetadataAll, cTokenUnderlyingPriceMap, currentChainId, cTokenBalancesAll)
        : {},
    [cTokenBalancesAll, cTokenMetadataAll, cTokenUnderlyingPriceMap, currentChainId]
  )

  const summary = useMemo(() => getLighthouseData(balancesMap, accountLiquidity), [balancesMap, accountLiquidity])

  const disabledMarkets = disabledFeatures?.markets
  const isProtocolDisabled = disabledFeatures?.protocol?.disabled

  const enrichedMarkets = useMemo(
    () =>
      allMarkets && cTokenMetadataAll
        ? getEnrichedMarkets(
            allMarkets,
            cTokenMetadataAll,
            balancesMap,
            currentChainId,
            summary.borrowBalance,
            summary.borrowLimit,
            accountLimits,
            disabledMarkets
          )
        : [],
    [
      accountLimits,
      allMarkets,
      balancesMap,
      cTokenMetadataAll,
      currentChainId,
      disabledMarkets,
      summary.borrowBalance,
      summary.borrowLimit,
    ]
  )

  // We filter the Distribution Token market (cDEEPR) for the release.
  // This market could be reinstated once the protocol is live.
  // Filtering is implemented at this stage to be able to return the rawMarkets object
  // with all the markets, necessary for the distributionToken price information
  // TODO: Review this market exclusion once the protocol is live
  const distributionToken = find(tokens[currentChainId], 'isDistributionToken')
  const filteredMarkets = filter(enrichedMarkets, (market) => market.cToken !== distributionToken?.address)

  const supplyingMarkets = useMemo(
    () => filteredMarkets?.filter((market) => !!market?.supply.isSupplying),
    [filteredMarkets]
  )
  const borrowingMarkets = useMemo(
    () => filteredMarkets?.filter((market) => !!market?.borrow.isBorrowing),
    [filteredMarkets]
  )
  const canBorrow = useMemo(
    () => filteredMarkets?.some((market) => market?.supply?.balance > 0 && market?.supply?.isCollateral),
    [filteredMarkets]
  )

  const isSupplying = !!supplyingMarkets?.length
  const isBorrowing = !!borrowingMarkets?.length
  const hasMarkets = !!filteredMarkets?.length

  return {
    summary,
    supplyingMarkets,
    borrowingMarkets,
    markets: filteredMarkets,
    rawMarkets: enrichedMarkets,
    isSupplying,
    isBorrowing,
    hasMarkets,
    canBorrow,
    refetch: mutate,
    isLoading: data === undefined && !error,
    error,
    isProtocolDisabled,
  }
}
