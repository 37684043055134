import { useCallback } from 'react'
import {
  Avatar,
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useAccount } from 'wagmi'

import { DEFAULT_TOKEN_AMOUNT_DECIMALS, formatPriceNumberWithSymbol, formatTokenAmount, getTokenImage } from '~/helpers'
import { useClaimModal } from '~/hooks/useClaimModal'
import * as Analytics from '~/lib/analytics'
import Divider from '../common/Divider'
import { SmallButton } from '../layout/SmallButton'
import CloseModalButton from './CloseModalButton'
import ModalButton from './ModalButton'
import PendingTransaction from './PendingTransaction'

const formatTokenAmountDecimals = (value?: string | number) => {
  if (!value) {
    return '-'
  }

  return `${(typeof value === 'string' ? parseFloat(value) : value).toFixed(DEFAULT_TOKEN_AMOUNT_DECIMALS)}`
}

/**
 * Types
 */

interface Props {
  isOpen: boolean
  onClose: () => void
}

/**
 * Claim Modal
 */

const ClaimModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation('layout')

  const { claim, isPending, isBroadcasting, distributionToken, distributionTokenPrice } = useClaimModal()

  const handleClaimClick = async () => {
    const success = await claim()

    if (success) {
      onClose()
    }
  }

  const { underlyingSymbol, unclaimedBalance, totalBalance, balance, underlyingDecimals, underlyingTokenAddress } =
    distributionToken
  const { connector } = useAccount()

  const isClaimEnabled = Number(formatTokenAmountDecimals(unclaimedBalance)) > 0

  const addTokenToWallet = useCallback(async () => {
    if (!underlyingTokenAddress) {
      return
    }

    try {
      await connector?.watchAsset?.({
        address: underlyingTokenAddress,
        symbol: underlyingSymbol || '',
        decimals: underlyingDecimals,
      })
    } catch (e) {
      Analytics.trackException(e)
    }
  }, [connector, underlyingDecimals, underlyingSymbol, underlyingTokenAddress])

  const shouldShowPendingStatus = isPending || isBroadcasting

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={{ base: 'full', md: 'md' }}>
      <ModalOverlay />
      <ModalContent>
        {shouldShowPendingStatus ? (
          <PendingTransaction isBroadcasting={isBroadcasting} onClose={onClose} />
        ) : (
          <>
            <ModalHeader fontSize={{ base: '16px', md: '20px' }}>
              {t('claimModal.deeprTokenName')} {t('claimModal.balance')}
              <CloseModalButton />
            </ModalHeader>
            <ModalBody p={6}>
              <Stack spacing={6} alignItems="center" w="full">
                <Avatar
                  mt={6}
                  size="xl"
                  name={underlyingSymbol}
                  src={getTokenImage(underlyingSymbol)}
                  bg="transparent"
                />
                {totalBalance !== undefined && (
                  <Box textAlign="center">
                    <Text fontSize="xl" fontWeight="700">
                      {formatTokenAmount(totalBalance, distributionToken.underlyingSymbol, 6)}
                    </Text>
                    {distributionTokenPrice && (
                      <Text color="text.tertiary" fontWeight="600">
                        {formatPriceNumberWithSymbol(distributionTokenPrice * totalBalance)}
                      </Text>
                    )}
                  </Box>
                )}
                <SmallButton
                  onClick={addTokenToWallet}
                  mt="12px"
                  py="4px"
                  px="12px"
                  color="rgba(255, 255, 255, 0.72)"
                  fontSize="12px"
                >
                  {t('claimModal.addTokenToWallet')}
                </SmallButton>
                <Divider />
                <Stack spacing={2} alignItems="flex-start" w="full">
                  <Flex w="full" justifyContent="space-between">
                    <Text fontWeight="500" color="text.secondary">
                      {t('claimModal.walletBalance')}
                    </Text>
                    <Text fontWeight="600" color="text.primary">
                      {formatTokenAmountDecimals(balance)}
                    </Text>
                  </Flex>
                  <Flex w="full" justifyContent="space-between">
                    <Text fontWeight="500" color="text.secondary">
                      {t('claimModal.unclaimed')}
                    </Text>
                    <Text fontWeight="600" color="text.primary">
                      {formatTokenAmountDecimals(unclaimedBalance)}
                    </Text>
                  </Flex>
                  <Flex w="full" justifyContent="space-between">
                    <Text fontWeight="500" color="text.secondary">
                      {t('claimModal.price')}
                    </Text>
                    <Text fontWeight="600" color="text.primary">
                      {formatPriceNumberWithSymbol(distributionTokenPrice || 0)}
                    </Text>
                  </Flex>
                </Stack>
                <ModalButton isDisabled={!isClaimEnabled} onClick={handleClaimClick}>
                  {isClaimEnabled
                    ? `${t('claimModal.claim')} ${formatTokenAmountDecimals(unclaimedBalance)} ${
                        underlyingSymbol || t('claimModal.deeprTokenDefaultSymbol')
                      }`
                    : t('claimModal.noUnclaimedBalance')}
                </ModalButton>
              </Stack>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

/**
 * Exports
 */

export default ClaimModal
