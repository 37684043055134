import { Provider } from '@wagmi/core'
import { CompoundLens__factory, Comptroller__factory } from 'contract-types'
import { formatUnits } from 'ethers/lib/utils'
import { find } from 'lodash'
import useSWR, { SWRResponse } from 'swr'
import { useAccount, useProvider } from 'wagmi'

import { addresses } from '~/constants/addresses'
import { Token, tokens } from '~/constants/tokens'
import useSupportedNetworkId from './useSupportedNetworkId'

/**
 * Constants
 */

const REFRESH_INTERVAL = 13000

/**
 * Fetcher
 */

const fetcher = async (chainId: number, provider: Provider, account?: string) => {
  const addressesByNetwork = addresses[chainId]
  const comptrollerContract = Comptroller__factory.connect(addressesByNetwork.comptroller, provider)

  const compAddress = await comptrollerContract.getCompAddress()
  const compoundLensContract = CompoundLens__factory.connect(addressesByNetwork.lens, provider)
  const { getCompBalanceMetadataExt } = compoundLensContract.callStatic
  const token = account
    ? await getCompBalanceMetadataExt(compAddress, addressesByNetwork.comptroller, account)
    : undefined

  return {
    distributionToken: token,
  }
}

/**
 * Types
 */

export interface DistributionToken extends Partial<Token> {
  balance?: number
  unclaimedBalance?: number
  totalBalance?: number
  underlyingTokenAddress?: string
}

/**
 * useDistributionToken Hook
 */

export const useDistributionToken = (): {
  isLoading: boolean
  refetch: () => void
  error: SWRResponse['error']
  distributionToken: DistributionToken
} => {
  const { address } = useAccount()
  const currentChainId = useSupportedNetworkId()

  const provider = useProvider({ chainId: currentChainId })

  const { data, mutate, error } = useSWR(
    `distributionToken-${currentChainId}${address || ''}`,
    () => fetcher(currentChainId, provider, address),
    {
      refreshInterval: REFRESH_INTERVAL,
    }
  )

  let distributionTokenBalance
  let distributionTokenUnclaimedBalance
  let totalBalance = 0

  if (data?.distributionToken) {
    distributionTokenBalance = +formatUnits(data?.distributionToken.balance)
    distributionTokenUnclaimedBalance = +formatUnits(data?.distributionToken.allocated)
    totalBalance = distributionTokenBalance + distributionTokenUnclaimedBalance
  }

  const distributionTokenData = find(tokens[currentChainId], { isDistributionToken: true })

  return {
    distributionToken: {
      balance: distributionTokenBalance,
      unclaimedBalance: distributionTokenUnclaimedBalance,
      totalBalance,
      ...distributionTokenData,
    },
    refetch: mutate,
    isLoading: !data && !error,
    error,
  }
}
