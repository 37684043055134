export const tokenImages: { [key: string]: string } = {
  FETH: '/assets/token-images/ETH@2x.png',
  FUSDC: '/assets/token-images/USDC@2x.png',
  FUSDT: '/assets/token-images/USDT@2x.png',
  DEEPR: '/assets/token-images/deepr-icon@2x.png',
  FTM: 'https://assets-cdn.trustwallet.com/blockchains/binance/assets/FTM-A64/logo.png',
  FMIOTA: '/assets/token-images/MIOTA@2x.png',
  FBTC: '/assets/token-images/wBTC@2x.png',
  SMR: '/assets/token-images/SMR@2x.png',
  WEN: '/assets/token-images/WEN@2x.png',
  FISH: '/assets/token-images/FISH.svg',
}
